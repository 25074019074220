import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.starts-with.js";
import { useQuery } from "@vue/apollo-composable";
import { AllTenanciesDocument } from "~/graphql/generated/graphql";
export var useAllTenanciesQuery = function useAllTenanciesQuery(variables, columns) {
  var query = useQuery(AllTenanciesDocument, function () {
    var _ref = variables.value,
      sort = _ref.sort,
      pagination = _ref.pagination,
      filters = _ref.filters,
      rentCategory = _ref.rentCategory,
      _ref$includeFlags = _ref.includeFlags,
      includeFlags = _ref$includeFlags === void 0 ? false : _ref$includeFlags;
    var columnsSet = new Set(columns.value);
    var includeTenants = columnsSet.has("tenantName") || columnsSet.has("annualRent") || columnsSet.has("rentPsm") || columnsSet.has("monthlyRent") || columnsSet.has("monthlyRentPsm") || columnsSet.has("leaseStart") || columnsSet.has("leaseEnd") || columnsSet.has("arrears") || columnsSet.has("irrevocableUntilLandlord") || columnsSet.has("irrevocableUntilTenant") || columnsSet.has("leaseLength");
    var includeExternalIds = columnsSet.has("unitNumber");
    var includeSales = columnsSet.has("daysOnMarket");
    var includeValuations = columnsSet.has("brokerMandate");
    var includeReponsible = columnsSet.has("assignedTo");
    var includeProperty = columnsSet.has("property") || columnsSet.has("portfolio") || columnsSet.has("zip") || columnsSet.has("propertyAdminEnd") || columnsSet.has("propertyAdminStart");
    var includeCustomFields = Array.from(columnsSet.values()).some(function (col) {
      return col.startsWith("custom");
    });
    return {
      filters: filters,
      sort: sort,
      pagination: pagination,
      rentCategory: rentCategory,
      includeValuations: includeValuations,
      includeTenants: includeTenants,
      includeSales: includeSales,
      includeExternalIds: includeExternalIds,
      includeProperty: includeProperty,
      includeFlags: includeFlags,
      includeReponsible: includeReponsible,
      includeCustomFields: includeCustomFields
    };
  }, function () {
    var _variables$value, _variables$value$sort;
    return {
      keepPreviousResult: true,
      enabled: !!variables.value && !!columns.value,
      fetchPolicy: ((_variables$value = variables.value) === null || _variables$value === void 0 ? void 0 : (_variables$value$sort = _variables$value.sort) === null || _variables$value$sort === void 0 ? void 0 : _variables$value$sort.relation) === "custom_field" ? "no-cache" : "cache-first"
    };
  });
  var result = computed(function () {
    var _query$result$value$a, _query$result$value;
    return (_query$result$value$a = (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : _query$result$value.assetManagementTenancies.items) !== null && _query$result$value$a !== void 0 ? _query$result$value$a : [];
  });
  var loading = computed(function () {
    return query.loading.value || !columns.value;
  });
  var meta = computed(function () {
    var _query$result$value$a2, _query$result$value2;
    return (_query$result$value$a2 = (_query$result$value2 = query.result.value) === null || _query$result$value2 === void 0 ? void 0 : _query$result$value2.assetManagementTenancies.metadata) !== null && _query$result$value$a2 !== void 0 ? _query$result$value$a2 : undefined;
  });
  return {
    result: result,
    loading: loading,
    meta: meta,
    refetch: query.refetch
  };
};
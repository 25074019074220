function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import "core-js/modules/es6.map.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
/**
 * Example
 *
```ts
const filters = useQueryFilters<AssetManagementTenanciesQueryVariables>();

const getDaysOnMarketFilterById = function (id: number): DaysOnMarketValueConfig {
  return daysOnMarketValues.find((item) => item.id == id) || daysOnMarketValues[0];
};

filters.setValue("filters", () => {
  const { dayMin, dayMax } = getDaysOnMarketFilterById(daysOnMarket.value);

  return DaysOnMarketFilter(dayMin, dayMax);
});

filters.setValue("filters", TenancyDecomissionedFilter, { enabled: () => !inactive.value });

filters.setValue("filters", () => ({ matches: [{ status: ["in", salesStatuses.value] }] }), {
  enabled: () => !!salesStatuses.value.length,
});
```
 */
export var useQueryFilters = function useQueryFilters() {
  var filters = ref({});
  var keyedRefs = new Map();
  var setValue = function setValue(filterKey, compute) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var field = filters.value[filterKey] || (filters.value[filterKey] = []);
    var enabled = options.enabled;
    var newRef = computed(function () {
      return enabled && !enabled() ? undefined : compute();
    });
    if (options.key) {
      var _keyedRefs$get;
      var index = (_keyedRefs$get = keyedRefs.get(options.key)) !== null && _keyedRefs$get !== void 0 ? _keyedRefs$get : field.length;
      field[index] = newRef;
      keyedRefs.set(options.key, index);
    } else {
      field.push(newRef);
    }
    return newRef;
  };
  var build = function build() {
    var output = {};
    var filterKey;
    var _loop = function _loop() {
      var outputCollection = output[filterKey] || {
        groups: []
      };
      var field = filters.value[filterKey];
      var _iterator = _createForOfIteratorHelper(field),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _filters2;
          var filterRef = _step.value;
          var _filters = filterRef.value;
          if (_filters && !Array.isArray(_filters)) _filters = [_filters];
          (_filters2 = _filters) === null || _filters2 === void 0 ? void 0 : _filters2.forEach(function (filter) {
            return filter.matches.length && outputCollection.groups.push(filter);
          });
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      if (outputCollection.groups.length) output[filterKey] = outputCollection;
    };
    for (filterKey in filters.value) {
      _loop();
    }
    return output;
  };
  return {
    value: computed(build),
    setValue: setValue
  };
};
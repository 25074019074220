import { getTenancyDefaultUrlQuery } from "~/helpers/apollo/apollo-portfolio-filter-helpers";
import { useDefaultTenancyFilterBookmarkQuery } from "./queries/useDefaultTenancyFilterBookmarkQuery";
export var useUrlTabsPortfolio = function useUrlTabsPortfolio() {
  var t = useTranslator();
  var defaultFilterQuery = useDefaultTenancyFilterBookmarkQuery(computed(function () {
    return true;
  }));
  return computed(function () {
    var _defaultFilterQuery$r;
    return [{
      key: "overview",
      label: t("PORTFOLIO_OVERVIEW_PROPERTY_OVERVIEW"),
      default: true
    }, {
      key: "tenancies",
      label: t("PORTFOLIO_OVEVIEW_PROPERTY_TENANCIES")
    }, {
      key: "opex",
      label: t("PORTFOLIO_PROPERTY_OPEX")
    }, {
      key: "properties",
      label: t("PORTFOLIO_OVERVIEW_PROPERTIES")
    }, {
      key: "companies",
      label: t("PORTFOLIO_OVERVIEW_COMPANIES")
    }, {
      key: "balance",
      label: t("PORTFOLIO_OVEVIEW_PROPERTY_BALANCE"),
      query: getTenancyDefaultUrlQuery((_defaultFilterQuery$r = defaultFilterQuery.result.value) === null || _defaultFilterQuery$r === void 0 ? void 0 : _defaultFilterQuery$r.id)
    }];
  });
};
import "core-js/modules/es6.array.find.js";
import { getBudgetDateRange, getDateRange } from "~/helpers/portfolio/portfolio-balance-helpers";
import { PropertyBudgetsDocument } from "~/graphql/generated/graphql";
import { useQuery } from "@vue/apollo-composable";
export var useOpexSelectedDateRange = function useOpexSelectedDateRange(propertyId) {
  var preferencesQuery = usePreferencesNamespace("preferencesNamespacePortfolioPropertyOpex");
  var preferences = computed(function () {
    return preferencesQuery.result.value;
  });
  var budgetId = computed(function () {
    var _preferences$value, _preferences$value$pr;
    return propertyId.value ? (_preferences$value = preferences.value) === null || _preferences$value === void 0 ? void 0 : (_preferences$value$pr = _preferences$value.propertyBudgets[propertyId.value]) === null || _preferences$value$pr === void 0 ? void 0 : _preferences$value$pr[0] : undefined;
  });
  var budgets = useQuery(PropertyBudgetsDocument, function () {
    return {
      assetManagementPropertyId: propertyId.value
    };
  }, function () {
    return {
      enabled: !!(budgetId !== null && budgetId !== void 0 && budgetId.value) && !!propertyId.value
    };
  });
  var budget = computed(function () {
    var _budgets$result$value, _budgets$result$value2, _budgets$result$value3, _budgets$result$value4;
    return (_budgets$result$value = budgets.result.value) === null || _budgets$result$value === void 0 ? void 0 : (_budgets$result$value2 = _budgets$result$value.assetManagementProperty) === null || _budgets$result$value2 === void 0 ? void 0 : (_budgets$result$value3 = _budgets$result$value2.assetManagementBudgets) === null || _budgets$result$value3 === void 0 ? void 0 : (_budgets$result$value4 = _budgets$result$value3.items) === null || _budgets$result$value4 === void 0 ? void 0 : _budgets$result$value4.find(function (b) {
      return b.id === budgetId.value;
    });
  });
  return {
    loading: preferencesQuery.loading,
    result: computed(function () {
      var preferences = preferencesQuery.result.value;
      if (!preferences) return;
      return preferences.benchmark === "budget" && budget.value ? getBudgetDateRange(budget.value) : getDateRange(preferences.dateRange, preferences.periodType, preferences.customDateStart, preferences.customDateEnd);
    })
  };
};